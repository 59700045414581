.home-about-container {
  padding: 0px 20px;
  padding-bottom: 50px;
}
.home-about-container img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.home-about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
}
.home-about-container h2 {
  color: var(--p);
  font-weight: 600;
}
