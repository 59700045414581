.about-container {
  padding: 50px 20px;
  height: 250px;
  background: url("../img/about/bake.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ol);
  z-index: 10;
}
.about-container h2 {
  color: var(--w);
  z-index: 20;
  font-size: 50px;
}
.about-section {
  padding: 80px 100px;
}
.about-section img {
  width: 100%;
  border-radius: 15px;
}
.what-w-d {
  background-color: var(--s);
}

@media screen and (max-width: 992px) {
  .about-section {
    padding: 50px 20px;
  }
}
