.course-info-container {
}
.course-bread {
  width: 100%;
  height: 250px;
  position: relative;
}
.course-bread::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.course-bread img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.course-bread h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.course-content {
  padding: 80px 20px;
  padding-bottom: 50px;
}
.course-content p {
  text-transform: uppercase;
}
.course-content h4 {
  font-size: 30px;
}
.course-details {
  padding: 0 50px;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.c-details {
  width: 30%;
  padding: 20px;
  color: var(--w);
  background-color: var(--s);
  border-radius: 15px;
}
.course-details .icon {
  color: var(--w);
  font-size: 20px;
}
@media screen and (max-width: 992px) {
  .course-details {
    padding: 0px 20px;
    padding-bottom: 50px;
  }
  .c-details {
    width: 100%;
  }
}
