.product-page-container {
  background: url("../img/home/hero2.jpg");
  height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-page-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ol);
  z-index: 10;
}
.product-page-container h2 {
  color: var(--w);
  z-index: 20;
  font-size: 50px;
}
.product {
  padding: 10px;
  position: relative;
}
.p-img-cont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}
.product-container {
  padding: 80px 20px;
  border-top: 1px rgba(0, 0, 0, 0.3) !important;
  background-color: var(--s);
}
.product img {
  height: 200px;
  border-radius: 15px;
}
.course-desc h4 {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  padding: 5px;
  color: var(--p);
  font-size: 15px;
  border-radius: 15px;
}
.w-btn {
  width: 100%;
  border-radius: 5px;
  padding: 10px 0 !important;
}
