.student-verification-container {
  padding: 50px 30px;
}
.user-container {
  padding: 50px 0;
  padding-top: 0;
}

/* Certificate Page */
.course-list-container {
  padding-bottom: 50px;
}
.courses {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 30px;
  background-color: var(--s);
  color: var(--w);
}
.courses button {
  cursor: pointer;
}
