.hero-bg {
  background: url("../../img/home/hero4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
.hero-container {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.hero-video-container {
  display: flex;
  justify-content: center;
}
.hero-container span {
  color: var(--w);
}
.hero-container h2 {
  color: var(--w);
  font-size: 80px;
}
.hero-container video {
  border-radius: 20px;
  width: 300px;
  height: 500px;
}
@media screen and (max-width: 700px) {
  .hero-container {
    padding: 50px 20px;
    min-height: 80vh;
  }
  .hero-container h2 {
    color: var(--w);
    font-size: 35px;
  }
  .hero-container video {
    border-radius: 20px;
    width: 100%;
    height: auto;
  }
}
