.admin-dashboard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  color: #333;
}
.dash-card {
  width: 23%;
  background-color: #fff;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0 40px;
  margin-bottom: 20px;
  cursor: pointer;
}
.dash-card:hover {
  background-color: #eee;
}
.dash-card .count {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.dash-card .icon {
  font-size: 40px;
  margin-bottom: 25px;
  transition: 0.3s all ease;
}
.dash-card:hover.dash-card .icon {
  font-size: 50px;
}

/* ================ */
.admin-recent-things {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recent-orders,
.recent-queries {
  width: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow-x: scroll;
}

.top-buyer {
  width: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.a-btn {
  background-color: #ffca00;
  color: var(--w);
  padding: 10px 20px;
  border: transparent;
  outline: transparent;
  &:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 992px) {
  .dash-card {
    width: 100%;
  }
}
