@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap'); */
:root {
  --p: #5b2d17;
  --s: #5b2d179d;
  --w: #fff;
  --b: #000;
  --ol: rgba(91, 45, 23, 0.8);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Cormorant", serif; */
  font-family: "Jost", sans-serif;
  list-style: none !important;
  text-decoration: none !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
