.c-btn {
  padding: 15px 30px;
  border: transparent;
  outline: transparent;
  color: var(--p);
  background-color: transparent;
  border: 2px solid var(--p);
  transition: 0.3s all ease;
  border-radius: 10px;
  &:hover {
    background-color: var(--p);
    color: var(--w);
  }
}

/* LOADER  */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  text-align: center;
  padding: 20px;
}
.loader {
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #ebef29;
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #000;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

.loading.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b8b8b8;
}

.loading.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 1s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.hrline {
  position: relative;
}
.hrline::before {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 100px;
  background-color: #ebef29;
}
