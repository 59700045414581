.reviews-container {
  padding: 80px 100px;
  overflow: hidden;
}
.reviews-container .rev-img {
  padding: 10px;
}
.reviews-container img {
  width: 100%;
}
.reviews-container h2 {
  color: var(--p);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 3px;
    width: 100px;
    background-color: var(--p);
  }
}
.rev-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 786px) {
  .reviews-container {
    padding: 80px 20px;
  }
}
